<template>
  <a-modal
    v-model:visible="visibleModal"
    title="选择商品"
    okText="确定"
    cancelText="取消"
    width="980px"
    :bodyStyle="{ height: '70vh', overflow: 'auto' }"
    :confirm-loading="confirmLoading"
    @cancel="close"
    @ok="handleSubmit"
  >
    <div class="between">
      <a-form :model="searchFormData.data" style="flex: 1">
        <a-row :gutter="16">
          <a-col :span="6" v-if="hasStock">
            <a-form-item label="仓库">
              <a-select
                style="width: 100%"
                v-model:value="searchFormData.data.stockId"
                placeholder="选择仓库"
                :options="stockAll"
              >
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="商品名称">
              <a-input v-model:value="searchFormData.data.goodsName" style="width: 100%" placeholder="输入商品名称" />
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="商品规格">
              <a-input
                v-model:value="searchFormData.data.specSkuValue"
                style="width: 100%"
                placeholder="输入商品规格"
              />
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="货号">
              <a-input v-model:value="searchFormData.data.itemNo" style="width: 100%" placeholder="输入货号" />
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
      <a-space>
        <a-button @click="reset">重置</a-button>
        <a-button type="primary" @click="search(pagetableRef)">搜索</a-button>
      </a-space>
    </div>

    <a-divider style="height: 2px; background-color: #2593fc" />
    <page-table
      ref="pagetableRef"
      :columns="props.columns"
      :queryApi="props.queryApi"
      rowKey="id"
      :hasAdd="false"
      :queryForm="searchFormData.data"
      :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
    >
      <template v-slot:bodyCell="{ scope }">
        <template v-if="scope.column.dataIndex == 'cover'">
          <cus-image :width="78" :src="scope.record.cover"></cus-image>
        </template>
        <template v-if="scope.column.dataIndex == 'originPrice'">
          <span>{{ $filters.money(scope.record.originPrice) }}</span>
        </template>
        <template v-if="scope.column.dataIndex == 'price'">
          <span>{{ $filters.money(scope.record.price) }}</span>
        </template>
      </template>
    </page-table>
  </a-modal>
</template>

<script setup>
import { computed, reactive, ref, onMounted } from 'vue'
import { message } from 'ant-design-vue'
import { useMapGetters } from '@/hooks/useMapGetters'
import { useTable } from '@/hooks'

const pagetableRef = ref('pagetableRef')
// 弹窗显示
const visibleModal = ref(false)
// 弹窗loading
const confirmLoading = ref(false)
const { field: stockAll } = useMapGetters('global/stockAll')
const emit = defineEmits(['onSuccess'])
const { search } = useTable()

// 父组件传递的props
const props = defineProps({
  queryApi: Function,
  // 表格列
  columns: {
    type: Array,
    default: [
      {
        title: '封面',
        dataIndex: 'cover',
        key: 'cover',
        align: 'center',
      },
      {
        title: '商品名称',
        dataIndex: 'goodsName',
        key: 'goodsName',
        align: 'center',
      },
      {
        title: '商品规格',
        dataIndex: 'specSkuValue',
        key: 'specSkuValue',
        align: 'center',
      },
      {
        title: '货号',
        dataIndex: 'itemNo',
        key: 'itemNo',
        align: 'center',
      },
      {
        title: '进价',
        dataIndex: 'originPrice',
        key: 'originPrice',
        align: 'center',
      },
      {
        title: '售价',
        dataIndex: 'price',
        key: 'price',
        align: 'center',
      },
    ],
  },
  // 仓库id
  stockId: {
    type: [Number, String],
    default: 0,
  },
  // 是否有仓库筛选
  hasStock: {
    type: Boolean,
    default: true,
  },
})

const stockId = computed(() => props.stockId || '')

// 筛选表单
const searchFormData = reactive({
  data: {
    goodsName: '', // 商品名称
    specSkuValue: '', // 商品规格
    stockId: stockId.value,
    itemNo: '', //货号
  },
})

// 多选框-已选列表
const selectedRowKeys = ref([])
// 多选框-已选数据
const selectedRowData = ref([])

const show = () => {
  if (stockId.value) {
    searchFormData.data.stockId = stockId.value
  }
  visibleModal.value = true
  reset()
  // pagetableRef.value && pagetableRef.value.reload()
}

const showLoading = () => {
  confirmLoading.value = true
}

const hideLoading = () => {
  confirmLoading.value = false
}

const close = () => {
  selectedRowKeys.value = []
  confirmLoading.value = false
  visibleModal.value = false
}

// 多选选择
const onSelectChange = (keys, rows) => {
  selectedRowKeys.value = keys
  selectedRowData.value = rows
}

const handleSubmit = () => {
  emit('onSuccess', selectedRowData.value)
}

const reset = () => {
  searchFormData.data = {
    goodsName: '', // 商品名称
    specSkuValue: '', // 商品规格
    stockId: stockId.value,
    itemNo: '', // 货号
  }
  // 暂时解决了传递props不刷新，但不是最优解
  setTimeout(() => {
    search(pagetableRef.value)
  })
}

defineExpose({
  show,
  close,
  showLoading,
  hideLoading,
})
</script>

<style></style>
