import { createApp } from 'vue'
import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/styles/index.scss' // global css
import '@/permission' // permission control
import print from 'vue3-print-nb'
import PageTable from '@/components/PageTable/PageTable'
import DataTable from '@/components/DataTable/DataTable'
import CusImage from '@/components/CusImage/CusImage'
import CusSelect from '@/components/CusSelect/CusSelect'
import CusInputNumber from '@/components/CusInputNumber/CusInputNumber'
import ImageUpload from '@/components/ImageUpload/ImageUpload'
import ImageUploadBtn from '@/components/ImageUploadBtn/ImageUploadBtn'
import GoodsSelectModal from '@/components/GoodsSelectModal/GoodsSelectModal'

const app = createApp(App)
// 全局组件
app.component('page-table', PageTable)
app.component('data-table', DataTable)
app.component('cus-image', CusImage)
app.component('cus-select', CusSelect)
app.component('cus-input-number', CusInputNumber)
app.component('image-upload', ImageUpload)
app.component('image-upload-btn', ImageUploadBtn)
app.component('goods-select-modal', GoodsSelectModal)
// 全局过滤器
app.config.globalProperties.$filters = {
  money(value) {
    if (value) {
      return `￥${value.toFixed(3)}`
    } else {
      return `￥0.00`
    }
  },
}
app.use(store).use(router).use(Antd).use(print).mount('#app')
