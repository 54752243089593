import request from "@/utils/request";

// 获取图形验证码
export function fatchImgCaptcha(data) {
  return request({
    url: "/base/imgCaptcha",
    method: "get",
    data,
  });
}

// 获取用户信息-包含菜单
export function fatchUserInfo(data) {
  return request({
    url: "/admin/current/currentAdmin",
    method: "get",
    data,
  });
}

// 账号密码登录
export function fatchLogin(data) {
  return request({
    url: "/auth/admin/name",
    method: "post",
    data,
  });
}

// 退出登录
export function fatchLogout(data) {
  return request({
    url: "/auth/logout",
    method: "get",
    data,
  });
}
