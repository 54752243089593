const namespace = 'wms-system'

export const setItem = (key, value) => {
  const storage = JSON.parse(sessionStorage.getItem(namespace) || '{}')
  storage[key] = value
  sessionStorage.setItem(namespace, JSON.stringify(storage || {}))
}

export const getItem = (key) => {
  const storage = JSON.parse(sessionStorage.getItem(namespace) || '{}')
  return storage[key] || ''
}

export const removeItem = (key) => {
  const storage = JSON.parse(sessionStorage.getItem(namespace) || '{}')
  delete storage[key]
  sessionStorage.setItem(namespace, JSON.stringify(storage || {}))
}

export const clearStorage = (key) => {
  sessionStorage.removeItem(namespace)
}
