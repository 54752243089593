import { message } from 'ant-design-vue'
import { getItem, removeItem } from '@/utils/storage'

const typeMap = {
  delete: '删除',
  revoke: '撤销',
  cancel: '取消',
}

export function useTable() {
  const search = (pagetableRef) => {
    pagetableRef && pagetableRef.reload()
  }

  const mountedLoad = (pagetableRef) => {
    let isBack = getItem('isBack')
    setTimeout(() => {
      if (isBack) {
        pagetableRef.loadData()
      } else {
        pagetableRef.reload()
      }
      removeItem('isBack')
      removeItem('searchFormData')
    })
  }

  const onDeleteConfirm = (record, deleteApi, pagetableRef) => {
    deleteApi &&
      deleteApi({
        id: record.id || record,
      })
        .then((res) => {
          if (res.status === 0) {
            message.success(`删除成功`)
            pagetableRef && pagetableRef.loadData()
          } else {
            message.error(res.message || `删除失败`)
          }
        })
        .catch((err) => {
          console.log(err)
          message.error(err.message || `删除失败`)
        })
  }

  /**
   * 统一处理表格右侧操作列中-弹窗确认
   * @param {行数据} record
   * @param {操作api} operationApi
   * @param {表格ref} pagetableRef
   * @param {操作类型} type
   */
  const onPopConfirm = (record, operationApi, pagetableRef, type = 'delete') => {
    const msg = typeMap[type]
    operationApi &&
      operationApi({
        id: record.id || record,
      })
        .then((res) => {
          if (res.status === 0) {
            message.success(`${msg}成功`)
            pagetableRef && pagetableRef.loadData()
          } else {
            message.error(res.message || `${msg}失败`)
          }
        })
        .catch((err) => {
          console.log(err)
          message.error(err.message || `${msg}失败`)
        })
  }

  return {
    onDeleteConfirm,
    onPopConfirm,
    search,
    mountedLoad,
  }
}
