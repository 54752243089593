import { fatchRegionTree, fatchQueryCategoryAll, queryWarehouseListAll } from '@/api/base'
import { fatchRoleList } from '@/api/system'
import { setItem } from '@/utils/storage'

const state = () => ({
  // 省市区数据
  regionTreeData: [],
  // 所有商品分类数据
  categoryAll: [],
  // 获取全部角色
  roleAll: [],
  // 全部仓库数据
  stockAll: [],
})
const getters = {
  categoryAll: (state) => state.categoryAll,
  roleAll: (state) => state.roleAll,
  stockAll: (state) => state.stockAll,
}
const mutations = {
  // 设置省市区数据
  setRegionTreeData(state, list) {
    state.regionTreeData = list
  },
  // 设置分类数据-all
  setCategory(state, payload) {
    state.categoryAll = payload
  },
  // 设置角色
  setRole(state, payload) {
    state.roleAll = payload
  },
  // 设置仓库
  setStock(state, payload) {
    state.stockAll = payload
  },
}
const actions = {
  // 请求省市区数据
  async Get_Region({ commit }, userInfo) {
    fatchRegionTree().then((res) => {
      if (res.status === 0) {
        setItem('regionTreeData', res.data)
      }
    })
  },
  // 请求分类全部数据
  async Get_Category_All({ commit }, payload) {
    const res = await fatchQueryCategoryAll()
    if (res.status === 0) {
      const targetArr = res.data.map((v) => {
        return {
          label: v.name,
          value: v.id,
        }
      })
      commit('setCategory', targetArr || [])
    }
  },
  // 请求角色全部数据
  async Get_Role_All({ commit }, payload) {
    const res = await fatchRoleList()
    if (res.status === 0) {
      const targetArr = res.data.map((v) => {
        return {
          label: v.name,
          value: v.roleKey,
        }
      })
      commit('setRole', targetArr || [])
    }
  },
  // 请求仓库全部数据
  async Get_Stock_All({ commit }, payload) {
    const res = await queryWarehouseListAll()
    if (res.status === 0) {
      const targetArr = res.data.map((v) => {
        return {
          label: v.name,
          value: v.id,
        }
      })
      commit('setStock', targetArr || [])
    }
  },
}
export default { state, getters, mutations, actions }
